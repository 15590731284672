<template>
    <div class="container wallet">
        <div class="header">
        <div class="header-wrap">
            <div class="wallet-title">余额（元）</div>
            <div class="wallet-money">
                <div class="wallet-money-num">{{wallet_left_balance}}</div>
            </div>
            <div class="wallet-btn">
                <div>
                    <van-button @click="toPath" size="small" round>充值</van-button>
                    <!-- <van-button style="margin-left:20px" size="small" round>提现</van-button> -->
                </div>
            </div>
        </div>
        </div>
        <div class="main">
            <div class="wallet-log-title van-hairline--bottom">储值记录</div>
            <div class="wallet-log-list">
                <van-list
                    v-model="loading"
                    :finished="finished"
                    :finished-text="listFinishedText"
                    @load="onLoad"
                    >
                    <van-cell v-for="(item,index) in list" :key="index" :title="item.total_fee" :label="item.dateline"></van-cell>
                </van-list>
            </div>
        </div>
    <loadings :loading='searchLoading' />
  </div>
</template>

<script>
    import { Field, Toast, Cell } from 'vant'
    import Loadings from '@/components/loading'
    export default {
        name:"wallet",
        data() {
            return {
                money: null,
                isTab:0,
                loading:false,
                searchLoading:false,
                finished:false,
                list: [],
                listFinishedText: '没有更多了',
                pageSize: 10,
                pageCurrent: 1,
                wallet_left_balance:'0.00'
            }
        },
        components: {
            [Field.name]:Field,
            [Cell.name]:Cell,
            loadings:Loadings,
        },
        created () {
            this.searchLoading = true
        },
        methods: {
            getList(){
                this.$api.profile_wallet({ 'per-page': this.pageSize, page: this.pageCurrent  })
                .then(res=>{
                    this.wallet_left_balance = res._data.wallet_left_balance
                    this.list = Number(res._meta.currentPage) === 1 ? res.items : this.list.concat(res.items)
                    this.finished = this.list.length >= res._meta.totalCount
                    this.listFinishedText = this.list.length ? '没有更多了~' : '暂无相关数据~'
                    this.pageCurrent += 1
                    this.loading = false
                    this.searchLoading = false
                })
            },
            onLoad(){
                this.getList()
            },
            toPath(){
                this.$router.push({path:'/recharge/center'})
            }
        },
    }
</script>

<style lang="less" scoped>
  .header{
    height: 277px;
    background-image: url('~@/assets/user_bg.png');
    background-size: 100% 100%;
    &-wrap{
        padding-top: 20px;
        text-align: center;
    }
    &-img{
      width:82px;
      height:82px;
      border-radius:50%;
      background: #fff;
      position: relative;
    }
  }
  .main{
    margin:-130px 15px 15px 15px;
    border-radius: 8px;
    overflow: hidden;
    background:#fff;
  }
  .wallet{
    &-title{
        margin-bottom: 10px;
        color: #fff;
    }
    &-money{
        margin-bottom: 10px;
        &-num{
            font-size: 24px;
            font-weight: bold;
            color: #fff;
        }
    }
    &-btn{
        .van-button--small{
            width: 70px;
        }
    }
    &-log{
        &-title{
            text-align: center;
            padding: 10px 0;
        }
        &-list{
            height: 400px;
            overflow-y: auto;
        }
    }
  }
</style>